import React, { useEffect, useState } from 'react';
import DownloadIcon from '../assets/icons/download-document-svgrepo-com.svg';
import { Link } from 'gatsby';

const Documents = ({ documents, user, rerender }) => {
    // const url = process.env.GATSBY_BACKEND_URL+"/api/documents/get-document?id=";
    // const url = 'https://mymathscloud.com/api/purchased/';
    const url = (id, jwt) => `${process.env.GATSBY_BACKEND_URL}/api/purchased/${id}?access_token=${jwt}`;
    const [docs, setDocs] = useState(
        documents.sort((a, b) => a.module.slug.localeCompare(b.module.slug)),
    );

    const sortClickHandler = (filter) => {

        switch (filter) {
            case 'module':
                docs.sort((a, b) => a.module.slug.localeCompare(b.module.slug));
                break;
            case 'section':
                docs.sort((a, b) => a.documentCategory[0]?.name.localeCompare(b.documentCategory[0].name));
                break;
            case 'document':
                docs.sort((a, b) => a.name.localeCompare(b.name));
                break;
        }
        setDocs(documents);
        rerender();
    };

    let module;
    let title;
    let category;
    let subTitle;

    return (
        <div>
            {!!docs?.length ? (
                <>
                    <div className="owned-title-container">
                        <div
                            className="owned-module owned-title"
                            onClick={() => {
                                sortClickHandler('module');
                            }}
                        >
                            Module
                        </div>
                        <div
                            className="owned-section owned-title"
                            onClick={() => {
                                sortClickHandler('section');
                            }}
                        >
                            Section
                        </div>
                        <div
                            className="owned-document owned-title"
                            onClick={() => {
                                sortClickHandler('document');
                            }}
                        >
                            Document
                        </div>

                    </div>

                    {!!docs.length &&
                        docs.map((item) => {
                            title = undefined;
                            subTitle = undefined;
                            if (module !== item.module.slug) {
                                category = undefined;
                                module = item.module.slug;
                                title = item.module.title;
                            }
                            if (category !== item.documentCategory[0]?.name) {
                                category = item.documentCategory[0]?.name;
                                subTitle = item.documentCategory[0]?.name;
                            }
                            const jwt = localStorage.getItem('jwt');
                            const href = url(item.id, jwt);
                            return (
                                <div
                                    key={item.id}
                                    className={title ? 'newSection owned-title-container' : 'owned-title-container'}
                                >
                                    <div className={title ? 'owned-module' : 'owned-module no-view'}>
                                        {title ? title : ''}
                                    </div>
                                    <div className={subTitle ? 'owned-section' : 'owned-section no-view'}>
                                        {subTitle ? subTitle : ''}
                                    </div>
                                    <div className="cart-item owned-document">
                                        <a href={href}>{item.name}</a>
                                    </div>
                                    <div className="cart-item">
                                        <a href={href}>
                                            <img src={DownloadIcon} alt="DownloadIcon" />
                                        </a>
                                    </div>
                                </div>
                            );
                        })}
                    <div className="password-info">
                        <span>The password for each of the documents is your profile email address.</span>
                    </div>
                </>
            ) : (
                <div className="mx-auto text-center">
                    <h3>No Documents owned as yet</h3>
                </div>
            )}
        </div>
    );
};

export default Documents;
